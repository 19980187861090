document.addEventListener('DOMContentLoaded', () => {
    const menuItems = document.querySelectorAll('.navbar-menu-item.has-submenu');

    menuItems.forEach(menuItem => {
        const submenuToggle = document.createElement('button');
        submenuToggle.classList.add('submenu-toggle');
        submenuToggle.innerHTML = '+';
        submenuToggle.setAttribute('aria-expanded', 'false');

        submenuToggle.addEventListener('click', (event) => {
            event.preventDefault();
            const submenu = menuItem.querySelector('.submenu');
            const isExpanded = submenuToggle.getAttribute('aria-expanded') === 'true';

            submenu.classList.toggle('show', !isExpanded);
            submenuToggle.setAttribute('aria-expanded', !isExpanded);
            submenuToggle.innerHTML = isExpanded ? '+' : '-';
        });

        menuItem.querySelector('a').after(submenuToggle);
    });
});
