/* global bootstrap: false */
(function () {
  'use strict'
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl)
  })
})()

/* sidebar */

document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector(".programListing-sidebar-toggle-header, .flexibleInner-sidebar-toggle-header");
  const content = document.querySelector(".programListing-sidebar-toggle-content, .flexibleInner-sidebar-toggle-content");

  // Proceed only if both elements are found in the DOM
  if (header && content) {
    // Define the mobile breakpoint (change if needed)
    const mobileWidth = 992;

    // Function to toggle classes on header and content
    function toggleCollapsed() {
      if (window.innerWidth <= mobileWidth) {
        header.classList.toggle("collapsed");
        content.classList.toggle("collapsed");
      }
    }

    // Function to reset the state when the screen expands beyond mobile width
    function resetState() {
      if (window.innerWidth > mobileWidth) {
        header.classList.add("collapsed");
        content.classList.add("collapsed");
      }
    }

    // Add event listener to the header for toggling
    header.addEventListener("click", toggleCollapsed);

    // Add event listener for window resize to reset on larger screens
    window.addEventListener("resize", resetState);

    // Initialize state (in case the page is loaded on desktop size first)
    resetState();
  }
});
