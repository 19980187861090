// src/js/slickCarousel.js
import $ from 'jquery';
import '@accessible360/accessible-slick/slick/slick.js';

$(document).ready(function () {
    let swipeStartX = 0;
    let swipeEndX = 0;
    let swipeStartTime = 0;
    let swipeEndTime = 0;

    $('.mobile-carousel').slick({
        slidesToShow: 6,
        slidesToScroll: 1, // Set to 1 to leverage `swipeToSlide`
        swipeToSlide: true,
        touchThreshold: 10, // Increase the threshold for more sensitivity
        speed: 300, // Adjust the speed of the transition between slides
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: '60px',
                    slidesToShow: 3.5,
                    centerMode: false,
                    arrows: false,
                    dots: true,
                    infinite: false,
                    swipeToSlide: true,
                    touchThreshold: 10,
                    speed: 300,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    centerPadding: '60px',
                    slidesToShow: 2.5,
                    centerMode: false,
                    arrows: false,
                    dots: true,
                    infinite: false,
                    swipeToSlide: true,
                    touchThreshold: 10,
                    speed: 300,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    centerPadding: '60px',
                    slidesToShow: 1.5,
                    centerMode: false,
                    arrows: false,
                    dots: true,
                    infinite: false,
                    swipeToSlide: true,
                    touchThreshold: 10,
                    speed: 300,
                }
            }
        ]
    });

    // Adding custom momentum-driven scroll functionality
    $('.mobile-carousel').on('touchstart', function (event) {
        swipeStartX = event.originalEvent.touches[0].pageX;
        swipeStartTime = new Date().getTime();
    });

    $('.mobile-carousel').on('touchend', function (event) {
        swipeEndX = event.originalEvent.changedTouches[0].pageX;
        swipeEndTime = new Date().getTime();

        const swipeDistance = swipeEndX - swipeStartX;
        const swipeDuration = swipeEndTime - swipeStartTime;
        const swipeVelocity = Math.abs(swipeDistance / swipeDuration);

        // Determine the number of slides to scroll based on swipe velocity
        let slidesToScroll = 1;
        if (swipeVelocity > 0.5) slidesToScroll = 2;
        if (swipeVelocity > 1) slidesToScroll = 3;
        if (swipeVelocity > 1.5) slidesToScroll = 4;

        const currentIndex = $('.mobile-carousel').slick('slickCurrentSlide');
        const direction = swipeDistance < 0 ? 1 : -1; // Determine swipe direction
        const targetIndex = currentIndex + slidesToScroll * direction;

        // Smoothly scroll to calculated target index
        $('.mobile-carousel').slick('slickGoTo', targetIndex, true);
    });
});
