document.addEventListener("DOMContentLoaded", function() {
  const mobileMenuToggle = document.querySelector(".mobile-menu-toggle");
  const headerFlex = document.querySelector(".header-flex");

  function toggleMenu() {
    mobileMenuToggle.classList.toggle("open");
    headerFlex.classList.toggle("open");
    document.body.classList.toggle("menu-open");

    // Change button text
    if (mobileMenuToggle.classList.contains("open")) {
      mobileMenuToggle.textContent = "Close";
    } else {
      mobileMenuToggle.textContent = "Menu";
    }
  }

  mobileMenuToggle.addEventListener("click", toggleMenu);

  window.addEventListener("resize", function() {
    if (window.innerWidth > 1280) {
      // Reset the toggles to their original state
      if (mobileMenuToggle.classList.contains("open")) {
        mobileMenuToggle.classList.remove("open");
        headerFlex.classList.remove("open");
        document.body.classList.remove("menu-open");
        mobileMenuToggle.textContent = "Menu";
      }
    }
  });
});
