document.addEventListener("DOMContentLoaded", function() {
    const searchContainer = document.querySelector(".search-container");
    const searchInput = document.querySelector(".search-input");
    const closeButton = document.querySelector(".close-button");
    const searchWrapper = document.querySelector(".search-container-wrapper");

    searchInput.addEventListener("focus", function() {
        if (window.innerWidth > 1280) {
            searchContainer.classList.add("expanded");
            searchWrapper.classList.add("expanded");
            closeButton.style.display = "inline";
        }
    });

    closeButton.addEventListener("click", function() {
        searchContainer.classList.remove("expanded");
        searchWrapper.classList.remove("expanded");
        searchInput.value = "";
        searchInput.blur();
        closeButton.style.display = "none";
    });

    // Hide the close button on blur if the input is empty
    searchInput.addEventListener("blur", function() {
        if (!searchInput.value && window.innerWidth > 1280) {
            searchContainer.classList.remove("expanded");
            searchWrapper.classList.remove("expanded");
            closeButton.style.display = "none";
        }
    });
});
