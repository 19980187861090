document.addEventListener('DOMContentLoaded', () => {
  const video = document.getElementById('backgroundVideo');
  const button = document.getElementById('videoButton');

  // Check if both video and button elements exist
  if (!video || !button) {
    // If either element is missing, exit the function
    return;
  }

  // Ensure the video is muted
  video.muted = true;

  // listen for when the first frame of the video is loaded
  if (video.readyState >= 3) {
    button.classList.remove('visually-hidden');
  } else {
    video.addEventListener('loadeddata', function() {
      button.classList.remove('visually-hidden');
    });
  }

  // Initial state: video is playing
  button.classList.add('paused');

  button.addEventListener('click', () => {
    if (video.paused) {
      video.play();
      button.classList.add('paused');
    } else {
      video.pause();
      button.classList.remove('paused');
    }
  });

  // Optional: Toggle pause when video ends
  video.addEventListener('ended', () => {
      button.classList.remove('paused');
  });
});
  