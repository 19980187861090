// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// init Swiper:
var swiper = new Swiper(".mySwiper", {
  slidesPerView: 1.5,
  centeredSlides: false,
  spaceBetween: 20,
  grabCursor: true,
  freeMode: true, // Enables free mode for momentum-based swiping
  freeModeMomentum: true, // Allows momentum to continue scrolling based on swipe velocity
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    // When the window width is >= 768px (desktop)
    1400: {
      enabled: false, // Disable swiper on desktop
    },
    // When the window width is < 768px (mobile)
    0: {
      enabled: true, // Enable swiper on mobile
      slidesPerView: 1.5,
    }
  }
});
