// src/js/main.js
import $ from 'jquery';
window.jQuery = $; window.$ = $; // Make it available globally if needed by other scripts
// ... other imports

import '../scss/main.scss';
import Collapse from 'bootstrap/js/dist/collapse';
import Tab from 'bootstrap/js/dist/tab';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './background-video';
import './navbar';
import './mobile-menu';
import './search';
import './sidebars';
import './slickCarousel';
import './sticky-nav';
import './swiper';
import './top-destinations-menu';
import './program-listing';

// Since the files are within the slick subdirectory, adjust the paths accordingly
import '@accessible360/accessible-slick/slick/slick.scss';
import '@accessible360/accessible-slick/slick/slick-theme.scss'; // Optional, for default theme
