document.addEventListener('DOMContentLoaded', function () {
    // Get the section5 element (the main navigation)
    const section5 = document.querySelector('.section.section5');
    
    // Create a clone of the section5 for the sticky nav
    const stickyNav = section5.cloneNode(true);
    
    // Add a class to the cloned navigation for sticky styles
    stickyNav.classList.add('sticky-nav');
    
    // Append the cloned sticky nav to the body
    document.body.appendChild(stickyNav);

    // Initially hide the sticky nav
    stickyNav.style.display = 'none';

    // Function to handle the sticky nav behavior based on scroll and window width
    function handleStickyNav() {
        const viewportWidth = window.innerWidth;

        // Only enable sticky nav if the viewport width is greater than 1280px
        if (viewportWidth > 1280) {
            // Get the position of section5
            const section5Position = section5.getBoundingClientRect().top;
            
            // If section5 is at the top of the viewport or above, show the sticky nav
            if (section5Position <= 0) {
                stickyNav.style.display = 'block';
            } else {
                // Hide the sticky nav when section5 is in view
                stickyNav.style.display = 'none';
            }
        } else {
            // Hide the sticky nav if viewport is 1280px or less
            stickyNav.style.display = 'none';
        }
    }

    // Add event listener for scroll event
    window.addEventListener('scroll', handleStickyNav);

    // Add event listener for resize event to recheck viewport size when the window is resized
    window.addEventListener('resize', handleStickyNav);
});
