// Check if the element with ID 'areaOfStudy' exists on the page
if (document.getElementById('areaOfStudy')) {
    
    // Get all checkboxes with the class 'filter-checkbox'
    const checkboxes = document.querySelectorAll('.filter-checkbox');

    // Add event listener to each checkbox to handle changes
    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', function() {
            // Get the filter value from the checkbox's data-filter attribute
            const filterType = this.getAttribute('data-filter');

            // Get all the course listings (li elements)
            const courseListings = document.querySelectorAll('.program-list-item');

            // Loop through all the course listings
            courseListings.forEach(function(listing) {
                // Check if the listing's data-type matches the checkbox filter
                if (listing.getAttribute('data-type') === filterType) {
                if (checkbox.checked) {
                    // Show the listings that match the filter when checkbox is checked
                    listing.classList.remove('hide');
                } else {
                    // Hide the listings that match the filter when checkbox is unchecked
                    listing.classList.add('hide');
                }
                }
            });
        });
    });

    // Function to handle both checkboxes and dropdown filtering
    function filterCourses() {
    // Get the selected area of study from the dropdown
    const selectedArea = document.getElementById('areaOfStudy').value;

    // Get all course listings
    const courseListings = document.querySelectorAll('.program-list-item');

    // Loop through each listing and handle the filtering
    courseListings.forEach(function(listing) {
        // Get the type and area attributes of the listing
        const type = listing.getAttribute('data-type');
        const area = listing.getAttribute('data-area');

        // Initially assume the listing should be hidden
        let showListing = false;

        // Check if any of the checkboxes that correspond to the type are checked
        document.querySelectorAll('.filter-checkbox').forEach(function(checkbox) {
            const filterType = checkbox.getAttribute('data-filter');

            // If the checkbox is checked and the listing matches the type, consider it for display
            if (checkbox.checked && filterType === type) {
                showListing = true;
            }
        });

        // After checkbox check, also filter by dropdown if an area other than "all" is selected
        if (selectedArea !== 'all' && area !== selectedArea) {
            showListing = false; // Hide the listing if it doesn't match the dropdown filter
        }

        // Show or hide the listing based on the combined checkbox and dropdown filters
        if (showListing) {
            listing.classList.remove('hide');
        } else {
            listing.classList.add('hide');
        }
    });
    }

    // Add event listeners to checkboxes
    document.querySelectorAll('.filter-checkbox').forEach(function(checkbox) {
    checkbox.addEventListener('change', filterCourses);
    });

    // Add event listener to the dropdown
    document.getElementById('areaOfStudy').addEventListener('change', filterCourses);

    // Run the filtering function initially to ensure proper filtering on page load
    filterCourses();
}